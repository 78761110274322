import {APP_BASE_HREF} from '@angular/common';
import {Component, OnInit, Inject} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {AppConfig} from 'src/app/common/config/app.config';
import {UserService} from 'src/app/data/user/user.service';
import {TenantThemeService} from '../../../data/tenant-theme/tenant-theme.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NavVisibilityService} from '../../../common/nav-visbility.service';
// @ts-ignore
import navTenantLinks from 'src/assets/config/nav-tenant-links.config.json';

export interface ReportingSystem {
  showReporting: boolean;
  reportingLink: string;
}

export interface NavTenantLink {
  tenant: string;
  reportingSystem: ReportingSystem;
  practiceTest: string;
  assessmentPortal: string;
}

@Component({
  selector: 'sbdl-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit {

  logo$: Observable<string>;
  interimItemPortalUrl = '#';
  hasIaipAccess$: Observable<boolean>;
  isAuthenticated$!: Observable<boolean>;
  science = false;
  navVisibilityState: string;
  navTenantLinks: NavTenantLink[] = navTenantLinks;
  currentTenantLinks: NavTenantLink | null = null;
  hasReporting = false;
  reportingLink = 'link';
  hasAssessment = false;
  assessmentLink = 'link';
  california = false;

  constructor(
    @Inject(APP_BASE_HREF) public baseHref: string,
    private userService: UserService,
    private tenantThemeService: TenantThemeService,
    private navVisibilityService: NavVisibilityService,
  ) {
    this.hasIaipAccess$ = userService.hasIaipRole;
  }

  ngOnInit() {
    this.interimItemPortalUrl = AppConfig.settings.interimItemPortalUrl;
    this.isAuthenticated$ = this.userService.authenticated;
    this.tenantThemeService.currentTenantTheme$.subscribe(
      theme => {
        this.currentTenantLinks = this.navTenantLinks.find(link => link.tenant === theme.displayName) || null;
        if (theme.displayName === 'California' || theme.displayName === 'Oregon') {
          this.science = true;
        }
        if (theme.displayName === 'California') {
          this.california = true;
        }
      }
    );
    this.navVisibilityService.currentVisibility.subscribe(visibility => {
      this.navVisibilityState = visibility;
    });
    if (this.currentTenantLinks && this.currentTenantLinks.reportingSystem.reportingLink) {
      this.hasReporting = true;
      this.reportingLink = this.currentTenantLinks.reportingSystem.reportingLink;
    }
    if (this.currentTenantLinks && this.currentTenantLinks.assessmentPortal) {
      this.hasAssessment = true;
      this.assessmentLink = this.currentTenantLinks.assessmentPortal;
    }
  }
}
