<div class="app-container">
  <div class="skip-link">
    <a href="{{locationPath}}#main" tabindex="1">Skip to the main content.</a>
  </div>
  <div class="router-outlet" role="main">
    <div *ngIf="devEnv && !devLoggedIn">
      <sbdl-development-environment (devEnvState)="onDevEnvAction($event)"></sbdl-development-environment>
    </div>
    <div [ngStyle]="{'display': devEnv ? 'none' : 'block'}" class="full-height">
      <div *ngIf="routeLoading" class="route-loader">
        <div class="spinner-container">
          <i class="fas fa-spinner fa-pulse" aria-label="Loading..."></i>
        </div>
      </div>
      <router-outlet>
        <sbdl-alerts *ngIf="routerPath === '/search'"></sbdl-alerts>
      </router-outlet>
    </div>
  </div>
  <sbdl-footer *ngIf="!(resourcePageFooterView$ | async)"></sbdl-footer>
</div>

