<div class="nav-show-hide-container" *ngIf="navVisibilityState === 'lesson'">
  <nav class="navigation-container" role="navigation" aria-label="main" id="navigation-menu-lessons">
    <ul class="nav-links nav-lessons">
      <sbdl-nav-link
        title="Search for Lesson Plans"
        ariaLabel="Search for Lesson Plans"
        class="link-focus"
        [tabIndexLink]="'5'"
        [linkDestination]="['/search']"
        [specialClass]="'nav-header'"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="Instructional Resources"
        ariaLabel="Instructional Resources"
        class="link-focus"
        [tabIndexLink]="'5'"
        [linkDestination]="['/search', { resourceTypes: 'ir' }]"
        [specialClass]="'nav-top-section'"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="ELA Resources"
        ariaLabel="ELA Resources"
        class="side-nav-link"
        [tabIndexLink]="'5'"
        icon="far fa-book-open"
        [linkDestination]="['/search', { resourceTypes: 'ir', claims: 'ela-c1,ela-c2,ela-c3,ela-c4', contentArea: 'ela-c1,ela-c2,ela-c3,ela-c4' }]"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="Math Resources"
        ariaLabel="Math Resources"
        class="side-nav-link"
        [tabIndexLink]="'5'"
        icon="far fa-calculator-alt"
        [linkDestination]="['/search', {  resourceTypes: 'ir', claims: 'math-c1,math-c2,math-c3,math-c4', contentArea: 'math-c1,math-c2,math-c3,math-c4' }]"
      ></sbdl-nav-link>
      <span *ngIf="science === true">
        <sbdl-nav-link
          title="Science Resources"
          ariaLabel="Science Resources"
          class="side-nav-link"
          [tabIndexLink]="'5'"
          icon="far fa-flask"
          [linkDestination]="['/search', {  resourceTypes: 'ir', domains: 'ess,ls,ps,ets', contentArea: 'ess,ls,ps,ets' }]"
        ></sbdl-nav-link>
      </span>

      <!-- Interim Connections Playlists -->
        <sbdl-nav-link
          title="Interim Connections Playlists"
          ariaLabel="Interim Connections Playlists"
          class="side-nav-link"
          [tabIndexLink]="'5'"
          [linkDestination]="['/search', { resourceTypes: 'cp' }]"
          [specialClass]="'nav-top-section'"
        ></sbdl-nav-link>
        <sbdl-nav-link
          title="ELA Playlists"
          ariaLabel="ELA Playlists"
          class="side-nav-link"
          [tabIndexLink]="'5'"
          icon="far fa-book-open"
          [linkDestination]="['/search', { resourceTypes: 'cp', claims: 'ela-c1,ela-c2,ela-c3,ela-c4', contentArea: 'ela-c1,ela-c2,ela-c3,ela-c4' }]"
        ></sbdl-nav-link>
        <sbdl-nav-link
          title="Math Playlists"
          ariaLabel="Math Playlists"
          class="side-nav-link"
          [tabIndexLink]="'5'"
          icon="far fa-calculator-alt"
          [linkDestination]="['/search', {  resourceTypes: 'cp', claims: 'math-c1,math-c2,math-c3,math-c4', contentArea: 'math-c1,math-c2,math-c3,math-c4' }]"
        ></sbdl-nav-link>
    </ul>
    <!-- Login Note -->
    <div class="nav-links nav-login" *ngIf="!currentTenantLinks; else logoutTemplate">
      <div class="nav-login-note">
        <span class="header-text login-note">Log in at the top right to see all options</span>
      </div>
    </div>

    <ng-template #logoutTemplate>
      <div class="nav-links nav-login">
        <div class="nav-login-note">
          <span class="header-text login-note">Log out at the top right</span>
        </div>
      </div>
    </ng-template>
  </nav>
</div>

<div class="nav-show-hide-container" *ngIf="navVisibilityState === 'professional'">
  <nav class="navigation-container" role="navigation" aria-label="main" id="navigation-menu-professional">
    <ul class="nav-links nav-professional">
      <div class="nav-header no-link">
        Professional Learning
      </div>
      <sbdl-nav-link
        title="Professional Learning Resources"
        ariaLabel="Professional Learning Resources"
        [tabIndexLink]="'7'"
        [sbdlIcon]="'goal'"
        [linkDestination]="['/search', { resourceTypes: 'pl' }]"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="Accessibility Strategy Resources"
        ariaLabel="Accessibility Strategy Resources"
        [tabIndexLink]="'7'"
        icon="far fa-universal-access"
        [linkDestination]="['/search', { resourceTypes: 'as' }]"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="Formative Strategy Resources"
        ariaLabel="Formative Strategy Resources"
        [tabIndexLink]="'7'"
        [sbdlIcon]="'strategies'"
        [linkDestination]="['/search', {  resourceTypes: 'fs'}]"
      ></sbdl-nav-link>

      <div class="nav-top-section no-link">
        How to Use Resource Types
      </div>
      <sbdl-nav-link
        title="Interim Connections Playlist"
        ariaLabel="Interim Connections Playlist"
        [tabIndexLink]="'7'"
        icon="far fa-chart-network"
        [linkDestination]="'/landing/playlist'"
        [specialClass]="'nav-sub-link'"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="Instructional Resources"
        ariaLabel="Instructional Resources"
        [tabIndexLink]="'7'"
        [sbdlIcon]="'steps'"
        [linkDestination]="'/landing/instructional'"
        [specialClass]="'nav-sub-link'"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="Formative Strategies"
        ariaLabel="Formative Strategies"
        [tabIndexLink]="'7'"
        [sbdlIcon]="'strategies'"
        [linkDestination]="'/landing/formative'"
        [specialClass]="'nav-sub-link'"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="Accessibility Strategies"
        ariaLabel="Accessibility Strategies"
        [tabIndexLink]="'7'"
        icon="far fa-universal-access"
        [linkDestination]="'/landing/accessibility'"
        [specialClass]="'nav-sub-link'"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="Professional Learning Resources"
        ariaLabel="Professional Learning Resources"
        [tabIndexLink]="'7'"
        [sbdlIcon]="'goal'"
        [linkDestination]="'/landing/professional'"
        [specialClass]="'nav-sub-link'"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="Interim Assessment Item Portal"
        ariaLabel="Interim Assessment Item Portal"
        [tabIndexLink]="'7'"
        icon="fas fa-badge-check"
        [linkDestination]="'/landing/items'"
        [specialClass]="'nav-sub-link'"
      ></sbdl-nav-link>

    </ul>
    <!-- Login Note -->
    <div class="nav-links nav-login" *ngIf="!currentTenantLinks; else logoutTemplate">
      <div class="nav-login-note">
        <span class="header-text login-note">Log in at the top right to see all options</span>
      </div>
    </div>

    <ng-template #logoutTemplate>
      <div class="nav-links nav-login">
        <div class="nav-login-note">
          <span class="header-text login-note">Log out at the top right</span>
        </div>
      </div>
    </ng-template>
  </nav>
</div>

<div class="nav-show-hide-container" *ngIf="navVisibilityState === 'assessment'">
  <nav class="navigation-container" role="navigation" aria-label="main" id="navigation-menu-accessibility">
    <ul class="nav-links nav-lessons">
      <div class="nav-header no-link">
        Assessment Tools
      </div>
        <sbdl-nav-link
          title="Smarter Annotated Response Tool (SmART)"
          ariaLabel="SmART"
          icon="far fa-external-link"
          [linkDestination]="'https://smart.smarterbalanced.org/'"
        ></sbdl-nav-link>
        <sbdl-nav-link
          title="Sample Items"
          ariaLabel="Sample Items"
          icon="far fa-external-link"
          [linkDestination]="'https://sampleitems.smarterbalanced.org/'"
        ></sbdl-nav-link>
        <sbdl-nav-link
          title="Content Explorer"
          ariaLabel="Content Explorer"
          icon="far fa-external-link"
          [linkDestination]="'https://contentexplorer.smarterbalanced.org/'"
        ></sbdl-nav-link>
        <sbdl-nav-link
          title="ISAAP Tool"
          ariaLabel="ISAAP Tool"
          icon="far fa-external-link"
          [linkDestination]="'https://isaap.smarterbalanced.org/'"
        ></sbdl-nav-link>
        <sbdl-nav-link
          title="Remote Administration"
          ariaLabel="Remote Administration"
          icon="far fa-external-link"
          [linkDestination]="'https://remote.smartertoolsforteachers.org/interims/'"
        ></sbdl-nav-link>
        <sbdl-nav-link
          title="Starting Smarter"
          ariaLabel="Starting Smarter"
          icon="far fa-external-link"
          [linkDestination]="'https://startingsmarter.org/'"
        ></sbdl-nav-link>
        <sbdl-nav-link
          title="Interim Assessment Item Portal"
          ariaLabel="Interim Assessment Item Portal"
          icon="fas fa-badge-check"
          [linkDestination]="'landing/items'"
        ></sbdl-nav-link>
        <sbdl-nav-link
          title="DESMOS Calculator"
          ariaLabel="DESMOS Calculator"
          icon="far fa-external-link"
          [linkDestination]="'https://calculator.smarterbalanced.org/'"
        ></sbdl-nav-link>
      <sbdl-nav-link
        title="Practice Test"
        ariaLabel="Practice Test"
        icon="far fa-external-link"
        [linkDestination]="currentTenantLinks?.practiceTest"
        [isLinkDisabled]="!currentTenantLinks"
      ></sbdl-nav-link>
        <sbdl-nav-link
          title="Reporting System"
          ariaLabel="Reporting System"
          icon="far fa-external-link"
          [linkDestination]="reportingLink"
          [isLinkDisabled]="!hasReporting"
        ></sbdl-nav-link>
      <sbdl-nav-link
        title="Assessment Portal"
        ariaLabel="Assessment Portal"
        icon="far fa-external-link"
        [linkDestination]="assessmentLink"
        [isLinkDisabled]="!hasAssessment"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="Interim Assessment Viewing System"
        ariaLabel="Interim Assessment Viewing System"
        icon="far fa-external-link"
        *ngIf="california"
        [linkDestination]="'https://capt.cambiumtds.com/student/?a=ResponseEntry'"
      ></sbdl-nav-link>
      <sbdl-nav-link
        title="Interim Assessment Lookup Tool"
        ariaLabel="Interim Assessment Lookup Tool"
        icon="far fa-external-link"
        *ngIf="california"
        [linkDestination]="'https://www.caaspp-elpac.org/assessments/interim/caaspp-and-elpac/interim-assessment-lookup'"
      ></sbdl-nav-link>
    </ul>
    <!-- Login Note -->
    <div class="nav-links nav-login" *ngIf="!currentTenantLinks; else logoutTemplate">
      <div class="nav-login-note">
        <span class="header-text login-note">Log in at the top right to see all options</span>
      </div>
    </div>

    <ng-template #logoutTemplate>
      <div class="nav-links nav-login">
        <div class="nav-login-note">
          <span class="header-text login-note">Log out at the top right</span>
        </div>
      </div>
    </ng-template>
  </nav>
</div>
